
# Note that in dev this won't automatically recompile 



window.Fun ||= {}

window.Fun.app = "rotaville"

window.translations = {"color":"colour","html-lang":"en-GB","html_lang":"en-GB","FUN_APP":"rotaville","rota":"rota","rotas":"rotas","Rota":"Rota","Rotas":"Rotas","Rotaville":"Rotaville","rotaville":"rotaville","rotaville_com":"rotaville.com","Rotaville_com":"Rotaville.com","ROTAVILLE":"ROTAVILLE","R":"R","Revolutionise":"Revolutionise","Optimise":"Optimise","aged_care":"aged care","rota_planning":"Rota Planning"}

window.locale_loaded = "en_ROTAVILLE"
  
window.t = (key) ->
  if translations[key]
    return translations[key]
  else
    error_string = "MISSING_TRANSLATION(#{key})"
    console?.log error_string
    return error_string

