
import * as Sentry from '@sentry/browser';



Sentry.init({
  dsn: "https://2167231570d74901b73bddb0cebf0be8@sentry.io/6536",
  
  release: '531db5421660d5722bba6f06a75252405fea7ddb',
  
  linesOfContext: 500, 
  autoSessionTracking: false,
  environment: 'staging',
  ignoreErrors: [ 'DOMException: WRONG_DOCUMENT_ERR', 'Non-Error promise rejection captured', 'EvalError' ]
});


window.Sentry = Sentry

